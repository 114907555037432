import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  BreadCrumb,
  Background,
  SectionPageTitle,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"
import { Container, Section } from "../components/SectionTags"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"
import ElectronicMenu from "../components/Header/ElectronicMenu"
import MechanicalMenu from "../components/Header/MechanicalMenu"
import ServoMenu from "../components/Header/ServoMenu"
import HydraulicMenu from "../components/Header/HydraulicMenu"
import ImagingMenu from "../components/Header/ImagingMenu"

import IndustryFooterMenu from "../components/Footer/IndustryFooterMenu"
import RepairServiceFooterMenu from "../components/Footer/RepairServiceFooterMenu"
import AboutFooterMenu from "../components/Footer/AboutFooterMenu"
import KnowledgeHubFooterMenu from "../components/Footer/KnowledgeHubFooterMenu"
import OtherLinksFooterMenu from "../components/Footer/OtherLinksFooterMenu"
// import PartnersFooterMenu from "../components/Footer/PartnersFooterMenu"

const SitemapGrid = styled.div`
  box-sizing: border-box;
  column-gap: 15px;
  position: relative;
  column-count: 1;
  ${BreakpointUp.sm`
    column-count: 2;
  `}
  ${BreakpointUp.lg`
    column-count: 3;
  `}
`

const SitemapItem = styled.div`
  margin-bottom: 30px;
  & a {
    display: block;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding: 5px 0;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
        color: #0059b2;
        text-decoration: underline !important;
    }
  }
  & ul {
    list-style:none;
    margin: 0;
    
    & li {
      margin:5px 0;
    }
  }
  .dropdown{
    position: inherit;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    max-width: inherit;
    min-width: auto;
    .dropdown-arrow{display:none;}
    .dropdown-body{
        overflow-y: inherit;
        max-height: initial;
        a{
            font-family: 'UniNeueBook', sans-serif;
            font-size: 16px;
            line-height:28px;
            + div{
                display:none;
            }
        }
    }
  }
`

const SiteMapTitle = styled.h2`
    font-size: 20px;
    line-height: 30px;
    margin-bottom:0;
    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 32px;
    }
    @media (min-width: 992px) {
        font-size: 32px;
        line-height: 44px;
    }
    & a {
        font-family: 'UniNeueBold', sans-serif;
        font-weight: 800;      
        color: #000;
        font-size: 20px;
        line-height: 30px;
        @media (min-width: 768px) {
            font-size: 24px;
            line-height: 32px;
        }
        @media (min-width: 992px) {
            font-size: 32px;
            line-height: 44px;
        }        
        &:hover {
            color: #0059b2;
        }
    }
`


class Sitemap extends React.Component {    
  render() {
    return (
      <Layout>
        <Seo title="Sitemap" description="Sitemap" />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px" bgColor="#D7E7F5">          
            <Background right="0" height="100%"><IndustryHeroBg /></Background>
            <Container>
                <BreadCrumb>
                    <Link to="/"><span>Home</span></Link> Sitemap
                </BreadCrumb>
                <SectionPageTitle>Sitemap</SectionPageTitle>
            </Container>
        </Section>
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px">
            <Container>
                <SitemapGrid>
                    <SitemapItem>
                        <SiteMapTitle>
                            <Link to="/electronic-repair-services">Electronic Repair</Link> 
                        </SiteMapTitle>
                        <ElectronicMenu />
                    </SitemapItem>

                    <SitemapItem>
                        <SiteMapTitle>
                            <Link to="/mechanical-repair-services">Mechanical Repair</Link>
                        </SiteMapTitle>
                        <MechanicalMenu />
                    </SitemapItem>

                    <SitemapItem>
                        <SiteMapTitle>
                            <Link to="/servo-repair-services">Servo Repair</Link>
                        </SiteMapTitle>
                        <ServoMenu />
                    </SitemapItem>

                    <SitemapItem>
                        <SiteMapTitle>
                            <Link to="/hydraulic-repair-services">Hydraulic Repair</Link>
                        </SiteMapTitle>
                        <HydraulicMenu />
                    </SitemapItem>

                    <SitemapItem>
                        <SiteMapTitle>
                            <Link to="/kiosk-printer-repair-services">Kiosk & Printer Repair</Link>
                        </SiteMapTitle>
                        <ImagingMenu />
                    </SitemapItem>

                    <SitemapItem>
                        <SiteMapTitle>Industries We Serve!</SiteMapTitle>
                        <IndustryFooterMenu />
                    </SitemapItem>

                    <SitemapItem>
                        <SiteMapTitle>Repair Service</SiteMapTitle>
                        <RepairServiceFooterMenu />
                    </SitemapItem>
                    
                    <SitemapItem>
                        <SiteMapTitle>About AES</SiteMapTitle>
                        <AboutFooterMenu />
                    </SitemapItem>
                    
                    <SitemapItem>
                        <SiteMapTitle>Knowledge Hub</SiteMapTitle>
                        <KnowledgeHubFooterMenu />
                    </SitemapItem>
                   
                    <SitemapItem>
                        <SiteMapTitle>Other Links</SiteMapTitle>
                        <OtherLinksFooterMenu />
                    </SitemapItem>
                </SitemapGrid>
            </Container>
        </Section>
        <EmergencyEstimateCTA />
      </Layout>
    )
  }
}

export default Sitemap